import moment from "moment";
export const dateFormatter = v => {
    // v is a `Date` object
    if (!(v instanceof Date) || isNaN(v)) return;
    const pad = '00';
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();
    return `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`;
  };

  export const hourFormatter = v => {
    if (!(v instanceof Date) || isNaN(v)) return v;
    return moment(v).format("h:mmA");
  };
  
  export const dateParser = v => {
    // v is a string of "YYYY-MM-DD" format
    const match = /(\d{4})-(\d{2})-(\d{2})/.exec(v);
    if (match === null) return;
    const d = new Date(match[1], parseInt(match[2], 10) - 1, match[3]);
    if (isNaN(d)) return;
    return d;
  };

  export const currentDate = () => {
    return moment().toDate();
  };
  
  export const dateFormatterForDocId = (date) => {
      return moment(date).format("YYYY-MM-DD");
  };