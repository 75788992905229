import * as React from 'react';
import {
  Create,
  TextInput,
  required,
  SimpleForm,
  DateTimeInput,
  minValue,
  NumberInput,
} from 'react-admin';
import { currentDate, dateFormatterForDocId } from './util/date.util';

const transform = data => {
    let transformed = {};
    console.log(data.punch_in_date);
    if(data.driver_id != null) {
    transformed.employee_id ='OC:' + data.driver_id ;
    transformed.id = transformed.employee_id + ":" + dateFormatterForDocId(data.punch_in_date);
    }
    transformed.employee_name = data.driver_name;
    transformed.assigned_employee_id = data.assigned_employee_id;
    transformed.att_date = data.punch_in_date;
    transformed.punch_in_date = data.punch_in_date;
    transformed.punch_in_meter = data.punch_in_meter.toString();
    transformed.punch_out_date = data.punch_out_date;
    transformed.punch_out_meter = data.punch_out_meter.toString();

    transformed.role = "driver";
    transformed.is_on_call = true;
    return transformed;
}

export const DriverRequest = ({permissions, ...props}) => (
    <Create {...props} basePath='/attendacnce' title="Driver Request" resource="attendance" transform={transform}>
        <SimpleForm redirect={false}>
            <TextInput label="Driver Name" source="driver_name" validate={[required()]} />
            <TextInput label="Driver Id" source="driver_id" validate={[required()]} />
            <TextInput label="Assigned Employee ID" source="assigned_employee_id" validate={[required()]} />
            <DateTimeInput label="Punch In Date & Time" source="punch_in_date" validate={required()} />
            <NumberInput label="Punch In Odometer reading" source="punch_in_meter" validate={required()} />
            <DateTimeInput label="Punch Out Date & Time" source="punch_out_date" validate={required()} />
            <NumberInput label="Punch Out Odometer reading" source="punch_out_meter" validate={required()} />
        </SimpleForm>
    </Create>
);
